
import { ChatMessageType } from "@/store/chat.types";
import { dateStringToTimeAgo } from "@/utils/helpers";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Chats = namespace("chats");

@Component
export default class ChatMessage extends Vue {
  @Chats.State("timeStamp")
  timeStamp!: number;

  @Prop()
  message!: ChatMessageType;
  @Prop()
  isSender!: boolean;

  get isFile() {
    return this.message.attachment && this.message.attachment.type !== "image";
  }
  get isImage() {
    return this.message.attachment && this.message.attachment.type === "image";
  }
  get seen() {
    return this.message.users_read?.length;
  }
  get timeAgo() {
    return this.timeStamp && dateStringToTimeAgo(this.message.created_at);
  }
  get senderName() {
    if (this.isSender) return null;

    return null; //"djfhfjfj"; //this.message.user_id  "vasya";
  }
  get img() {
    if (this.message.attachment) {
      return `${this.message.attachment.file}`;
    } else return "";
  }
  get zoomLink() {
    if (this.message.text.includes("Your link to zoom")) {
      const url = this.message.text.replace("Your link to zoom: ", "");

      return url.trim() || null;
    }
    return null;
  }

  nl2br(str: string) {
    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + "<br />" + "$2"
    );
  }

  showImg() {
    if (this.img) {
      window.open(this.img, "_blank");
    }
  }
}
