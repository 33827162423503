
import TypingIndicator from "@/components/chat/TypingIndicator.vue";
import MessageInput from "./MessageInput.vue";

import { ChatList, ChatListType } from "@/store/chat.types";
import { Component, Vue } from "vue-property-decorator";
import ChatAvatar from "./ChatAvatar.vue";
import ChatListItem from "./ChatListItem.vue";
import ChatMessage from "./Message.vue";

import { namespace } from "vuex-class";

const Chats = namespace("chats");

@Component({
  components: {
    TypingIndicator,
    MessageInput,
    ChatMessage,
    ChatListItem,
    ChatAvatar
  }
})
export default class ChatSideBar extends Vue {
  @Chats.State("chats")
  chats!: ChatList;
  @Chats.State("privateChats")
  privateChats!: ChatListType[];
  @Chats.State("groupChats")
  groupChats!: ChatListType[];
  @Chats.State("currentlyOpenChatId")
  selectedChatId!: string;
  @Chats.State("activeTab")
  activeTab!: number;
  @Chats.Mutation("setActiveTab")
  setActiveTab!: (tab: number) => void;

  get chatType() {
    return this.activeTab === 1 ? "private" : "group";
  }

  get filteredChats() {
    return this.activeTab === 1 ? this.privateChats : this.groupChats;
  }
}
