
import TypingIndicator from "@/components/chat/TypingIndicator.vue";
import MessageInput from "./MessageInput.vue";

import {
  ChatList,
  ChatMessageType,
  MessagesMetaType
} from "@/store/chat.types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ChatAvatar from "./ChatAvatar.vue";
import ChatMessage from "./Message.vue";

const Chats = namespace("chats");
const Auth = namespace("auth");

@Component({
  components: {
    TypingIndicator,
    MessageInput,
    ChatMessage,
    ChatAvatar
  }
})
export default class ChatMessages extends Vue {
  @Auth.State("user")
  user!: any;
  @Chats.State("page")
  page!: number;
  @Chats.State("messagesMeta")
  messagesMeta!: MessagesMetaType;
  @Chats.Getter("isLoading")
  isLoading!: boolean;
  @Chats.State("currentlyOpenChatId")
  currentlyOpenChatId!: string;
  @Chats.State("messages")
  messages!: ChatMessageType[];
  @Chats.State("chats")
  chats!: ChatList;
  @Chats.State("activeTab")
  activeTab!: number;
  @Chats.Mutation("setPage")
  setPage!: (page: number) => void;
  @Chats.Mutation("resetChat")
  resetChat!: () => void;
  @Chats.Mutation("addMessage")
  addMessage!: (message: ChatMessageType) => void;
  @Chats.Mutation("setMessagesSeen")
  setMessagesSeen!: ({
    messageIds,
    userId
  }: {
    messageIds: number[];
    userId: number;
  }) => void;
  @Chats.Action("getMessagesByCurrentChatId")
  getMessagesByCurrentChatId!: (chatId: string) => void;
  @Chats.Action("makeMessagesSeen")
  makeMessagesSeen!: (data: { chatId: string; messageIds: number[] }) => void;
  @Chats.Action("updateGroupChannelsList")
  updateGroupChannelsList!: ({
    message,
    chatId
  }: {
    message: ChatMessageType;
    chatId: string;
  }) => void;
  @Chats.Action("updatePrivateChannelsList")
  updatePrivateChannelsList!: ({
    message,
    chatId
  }: {
    message: ChatMessageType;
    chatId: string;
  }) => void;
  @Chats.Action("generateZoomMeeting")
  generateZoomMeeting!: (eventId: string) => void;

  showScrollToBottomBtn = false;

  get otherUserInfo() {
    if (this.activeTab === 1) {
      return this.chats.private_chat.find(
        chat => chat.chat_id === this.currentlyOpenChatId
      )?.user.name;
    }
    if (this.activeTab === 2) {
      return this.chats.group_chat.find(
        chat => chat.chat_id === this.currentlyOpenChatId
      )?.group.name;
    }
    return "";
  }

  get isTyping() {
    return false;
  }

  scrollHandler(event: any) {
    const element = event.target;
    if (element.scrollTop == 0) {
      if (this.page < this.messagesMeta.last_page) {
        this.setPage(this.page + 1);

        this.getMessagesByCurrentChatId(this.currentlyOpenChatId);
      }
    }
    this.showScrollToBottomBtn =
      Math.abs(
        element.scrollHeight - element.clientHeight - element.scrollTop
      ) > 100;
  }

  scrollToBottom(smooth: any = true) {
    const messagesContainer = this.$refs.messagesContainer as Vue & {
      scrollTo: any;
      scrollHeight: any;
    };

    if (messagesContainer) {
      const reducedMotion = window.matchMedia(
        `(prefers-reduced-motion: reduce)`
      ).matches;
      messagesContainer.scrollTo({
        top: messagesContainer.scrollHeight,
        behavior: smooth && !reducedMotion ? "smooth" : "instant"
      });
    }
  }

  @Watch("isLoading")
  handleLoadingEnd(val: boolean) {
    if (!val && this.page == 1) {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  }

  reset() {
    this.resetChat();
  }

  @Watch("chats")
  onReady() {
    // group chats
    this.chats.group_chat.forEach(
      (chat: { chat_id: string; chat_name: string }) => {
        this.$echo
          .private(chat.chat_id)
          .listen(
            ".message.sent",
            ({ message }: { message: ChatMessageType }) => {
              this.handleMessageReceive(chat.chat_id, message);
            }
          )
          .listen(
            ".messages.read",
            ({
              messageIds,
              // eslint-disable-next-line @typescript-eslint/camelcase
              user_id
            }: {
              messageIds: number[];
              user_id: number;
            }) => {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.setMessagesSeen({ messageIds, userId: user_id });
            }
          );
      }
    );

    // private chats
    this.chats.private_chat.forEach(
      (chat: { chat_id: string; chat_name: string }) => {
        this.$echo
          .private(chat.chat_id)
          .listen(
            ".message.sent",
            ({ message }: { message: ChatMessageType }) => {
              this.handleMessageReceive(chat.chat_id, message, true);
            }
          )
          .listen(
            ".messages.read",
            ({
              messageIds,
              // eslint-disable-next-line @typescript-eslint/camelcase
              user_id
            }: {
              messageIds: number[];
              user_id: number;
            }) => {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.setMessagesSeen({ messageIds, userId: user_id });
            }
          );
      }
    );
  }

  handleMessageReceive(
    chatId: string,
    message: ChatMessageType,
    privateChat = false
  ) {
    if (chatId === this.currentlyOpenChatId) {
      this.addMessage(message);
      this.$nextTick(() => {
        this.scrollToBottom();
      });

      if (message.user_id != this.user.id) {
        this.makeMessagesSeen({
          chatId: chatId,
          messageIds: [message.id]
        });
      }
    }
    if (privateChat) {
      this.updatePrivateChannelsList({
        message,
        chatId: chatId
      });
    } else {
      this.updateGroupChannelsList({
        message,
        chatId: chatId
      });
    }
  }

  async createZoomLink() {
    const eventId = String(this.currentlyOpenChatId).split(".")[1];
    try {
      await this.generateZoomMeeting(eventId);
    } catch (e) {
      const err = e as any;
      this.$error({ err: err.result.message });
    }
  }
}
