export const questionType = {
  single: { id: 1, name: "Single" },
  multiple: { id: 2, name: "Multiple" },
  correspondence: { id: 3, name: "Correspondence" },
  essay: { id: 4, name: "Essay" },
  open: { id: 5, name: "Open" }
};

export const practicalModules = {
  skills: { id: 1, name: "Practical Skills" },
  experience: { id: 1, name: "Work Experience" }
};
