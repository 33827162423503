
import ChatComponent from "@/components/chat/ChatComponent.vue";
import { ChatList } from "@/store/chat.types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Chats = namespace("chats");

@Component({
  components: {
    ChatComponent
  }
})
export default class ChatPage extends Vue {
  @Chats.Action("getChatsList")
  getChatsList!: () => ChatList;

  async created() {
    try {
      await this.getChatsList();
    } catch (err) {
      const e = err as any;
      this.$error({ error: e.message });
    }
  }
}
