
import ChatAvatar from "@/components/chat/ChatAvatar.vue";
import { ChatListGoupItem, ChatListPrivateItem } from "@/store/chat.types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Chats = namespace("chats");
@Component({
  components: {
    ChatAvatar
  }
})
export default class ChatListItem extends Vue {
  @Chats.Mutation("setCurrentlyOpenChatId")
  setCurrentlyOpenChatId!: (id: string) => void;
  @Chats.Mutation("setGroupChatUsers")
  setGroupChatUsers!: (id: any) => void;
  @Chats.Mutation("resetChat")
  resetChat!: () => void;
  @Chats.Action("getMessagesByCurrentChatId")
  getMessagesByCurrentChatId!: (id: string) => void;
  @Chats.State("currentlyOpenChatId")
  currentlyOpenChatId!: string;

  @Prop({ default: false })
  isActive!: boolean;
  @Prop()
  chatItem!: ChatListGoupItem | ChatListPrivateItem;

  get user() {
    return { name: this.chatItem.chat_name, activeStatus: !true, avatar: null };
    // return this.chatItem.user;
  }
  get lastMessage() {
    return this.chatItem?.lastMessage;
  }

  select() {
    if (this.currentlyOpenChatId !== this.chatItem.chat_id) {
      this.resetChat();
      this.setCurrentlyOpenChatId(this.chatItem.chat_id);
      this.getMessagesByCurrentChatId(this.chatItem.chat_id);
      // if ((this.chatItem as any).group) this.setGroupChatUsers(this.chatItem);
    }
  }
}
