
import { attachmentValidate, toBase64 } from "@/utils/helpers";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Chats = namespace("chats");

@Component
export default class MessageInput extends Vue {
  @Chats.State("currentlyOpenChatId")
  currentlyOpenChatId!: number;
  @Chats.Action("sendMessage")
  sendMessage!: (message: any) => void;

  showPreview = false;
  message = "";
  file: any = null;
  type = "";
  imgURL = "";

  get canSubmit() {
    return !!this.message.trim();
  }

  get disabled() {
    return !this.currentlyOpenChatId;
  }

  get readonly() {
    return false;
  }

  get cantSend() {
    return !this.message && !this.file;
  }

  fileSelect() {
    (this.$refs.fileInput as Vue & { click: () => void }).click();
  }
  imageSelect() {
    (this.$refs.imageInput as Vue & { click: () => void }).click();
  }

  async handleFileChange(e: any) {
    this.file = e.target.files[0];
    const validateError = attachmentValidate(this.file);
    if (validateError) {
      this.$error({ error: validateError });
      return;
    }
    if (this.file.type.match("image/*")) {
      this.type = "image";
      this.imgURL = await toBase64(this.file);
    } else {
      this.type = "file";
    }
    this.showPreview = true;
  }

  send(e: any) {
    e.preventDefault();
    if (this.cantSend) return;

    this.showPreview = false;
    const message = {
      message: this.message,
      attachment: this.file
    };

    this.sendMessage(message);
    this.reset();
    this.$nextTick(() => {
      this.$emit("scrollToBottom", true);
      (this.$refs.input as Vue & { focus: () => void }).focus();
    });
  }

  cancelFile() {
    this.file = null;
    this.showPreview = false;
  }

  reset() {
    this.message = "";
    this.file = null;
    this.type = "";
    this.imgURL = "";
    this.showPreview = false;
  }
}
