
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ChatMessages from "./ChatMessages.vue";
import ChatSideBar from "./ChatSideBar.vue";

const Chats = namespace("chats");

@Component({
  components: {
    ChatSideBar,
    ChatMessages
  }
})
export default class ChatComponent extends Vue {
  @Chats.Mutation("setTimeStamp")
  setTimeStamp!: () => void;

  intervalId: any = null;

  mounted() {
    const chat = this.$refs.chat as Vue & {
      getBoundingClientRect: () => any;
      style: any;
    };

    const topOffset = chat.getBoundingClientRect().top + 15;
    chat.style.height = `calc(100vh - ${topOffset}px)`;

    this.intervalId = setInterval(() => {
      this.setTimeStamp();
    }, 60000);
  }

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
