import moment from "moment";
import { V } from "../main";
import { questionType } from "./constants";

const allAllowedExtensions = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "pdf",
  "zip",
  "rar",
  "txt"
];
const maxUploadSize = 5000000;

export function attachmentValidate(file: any) {
  const { name: fileName, size: fileSize } = file;
  const fileExtension = fileName.split(".").pop();
  if (!allAllowedExtensions.includes(fileExtension.toString().toLowerCase())) {
    return "file type not allowed";
  }
  // Validate file size.
  if (fileSize > maxUploadSize) {
    return "File is too large!";
  }
  return "";
}

export function dateStringToTimeAgo(dateString: Date): string {
  const now = new Date();
  const date = new Date(dateString);
  const seconds = Math.floor((Number(now) - Number(date)) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (seconds < 60) {
    return "just now";
  } else if (minutes < 60) {
    return `${minutes}m ago`;
  } else if (hours < 24) {
    return `${hours}h ago`;
  } else if (days < 7) {
    return `${days}d ago`;
  } else if (weeks < 4) {
    return `${weeks}w ago`;
  } else {
    return moment(dateString).format("DD/MM/YY");
  }
}

export function createObjectURL(object: any) {
  return window.URL
    ? window.URL.createObjectURL(object)
    : window.webkitURL.createObjectURL(object);
}

export function toBase64(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = error => reject(error);
  });
}

export function transformAnswers(question: any) {
  return question.variants.map((variant: string) => {
    let correct: boolean | string = question.answers.includes(variant);

    if (question.type.name === questionType.correspondence.name) {
      const answer = question.answers.find((answer: any) =>
        answer.includes(variant)
      );
      correct = answer ? answer.replace(variant, "") : "-";
    }
    return {
      answer: variant,
      correct
    };
  });
}

export function getFileType(file: string): "video" | "picture" | "file" {
  const videoExt = ["mp4", "mov", "avi", "wmv"];
  const pictureExt = ["jpeg", "png", "jpg", "gif"];

  const splitedFile = file.split(".");
  const extention = splitedFile[splitedFile.length - 1].toLowerCase();

  if (videoExt.find(ext => ext === extention)) {
    return "video";
  }
  if (pictureExt.find(ext => ext === extention)) {
    return "picture";
  }
  return "file";
}

export function formatGrade(item: any): number {
  return Number(item.toString().replace(/^0+/, ""));
}

export async function deleteConfirmation(
  text = "Do you really want to delete item?"
): Promise<"Yes" | "No"> {
  return await V.$dialog.error({
    text,
    title: "Warning",
    persistent: false,
    actions: {
      No: { text: "No" },
      Yes: {
        color: "red",
        text: "Yes I do"
      }
    }
  });
}

export const downloadFile = (
  file: any,
  name: string,
  extractExtension = true
) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");

  const typeArr = file.type.split("/");
  const ext = typeArr[typeArr.length - 1];

  const fileName = extractExtension ? ` ${name}.${ext}` : name;

  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const formateDate = (date: Date, withTime = false): string => {
  if (date) {
    return moment(date).format(withTime ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY");
  }
  return "-";
};

// et custom_scroll_short = document.getElementById('custom-scroll-short');
// custom_scroll_short.addEventListener("wheel", (event) => {
//       event.preventDefault();

//       let target = custom_scroll_short.scrollTop + event.deltaY * ratio;

//       custom_scroll_short.scrollTo({
//         top: target,
//         behavior: "smooth"
//       })
// });
