
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ChatAvatar extends Vue {
  @Prop()
  avatar!: string;
  @Prop()
  size!: string;
  @Prop({ required: false, default: "" })
  name!: string;

  sizes = ["l", "m", "s"];

  get style() {
    if (this.avatar) return { "background-image": `url(${this.avatar})` };
    return "";
  }

  get nameLetter() {
    return this.name[0].toUpperCase() + this.name[2];
  }
  get sizeClass() {
    if (this.sizes.includes(this.size)) {
      return `av-${this.size}`;
    } else return "";
  }
}
